import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { DEFAULT_PSM_OPTIONS } from 'angular-password-strength-meter/zxcvbn';
import { AlertModule } from 'ngx-bootstrap/alert';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { esLocale } from 'ngx-bootstrap/locale';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { initialConfig as NgxMaskConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { QuillModule } from 'ngx-quill';

import { AddPhoneComponent } from './components/add-phone/add-phone.component';
import { AssignmentSelectComponent } from './components/assignment-select/assignment-select.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { BatchDestinationComponent } from './components/batch-destination/batch-destination.component';
import { BatchDetailsComponent } from './components/batch-details/batch-details.component';
import { BatchFinderComponent } from './components/batch-finder/batch-finder.component';
import { BouncerComponent } from './components/bouncer/bouncer.component';
import { BulkInviteComponent } from './components/bulk-invite/bulk-invite.component';
import { CellphoneVerificationComponent } from './components/cellphone-verification/cellphone-verification.component';
import { CertificatesButtonComponent } from './components/certificates-button/certificates-button.component';
import { CertificatesTableComponent } from './components/certificates-table/certificates-table.component';
import { ColorSelectorComponent } from './components/color-selector/color-selector.component';
import { ColumnFilterComponent } from './components/column-filter/column-filter.component';
import { CommercialZonesManagerComponent } from './components/commercial-zones-manager/commercial-zones-manager.component';
import { CommercialZonesModalComponent } from './components/commercial-zones-modal/commercial-zones-modal.component';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { CompanyLogoComponent } from './components/company-logo/company-logo.component';
import { CompanyTableComponent } from './components/company-table/company-table.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ConnectorComponent } from './components/connector/connector.component';
import { DateMdComponent } from './components/date-md/date-md.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DividerLineComponent } from './components/divider-line/divider-line.component';
import { DoaComponent } from './components/doa/doa.component';
import { EasyStepsComponent } from './components/easy-steps/easy-steps.component';
import { EditInPlaceComponent } from './components/edit-in-place/edit-in-place.component';
import { EnvelopeStatusComponent } from './components/envelope-status/envelope-status.component';
import { EnvelopesModalsComponent } from './components/envelopes-modals/envelopes-modals.component';
import { ErrorListComponent } from './components/error-list/error-list.component';
import { ExpandableContentComponent } from './components/expandable-content/expandable-content.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FileManagerModalComponent } from './components/file-manager-modal/file-manager-modal.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { FilterDateRangeComponent } from './components/filter-date-range/filter-date-range.component';
import { FilterListComponent } from './components/filter-list/filter-list.component';
import { FilterMultipleListComponent } from './components/filter-multiple-list/filter-multiple-list.component';
import { FilterSelectComponent } from './components/filter-select/filter-select.component';
import { FilterSetComponent } from './components/filter-set/filter-set.component';
import { FiscalIdComponent } from './components/fiscal-id/fiscal-id.component';
import { FlagComponent } from './components/flag/flag.component';
import { FormReuseComponent } from './components/form-reuse/form-reuse.component';
import { GaugeComponent } from './components/gauge/gauge.component';
import { GmapViewComponent } from './components/gmap-view/gmap-view.component';
import { GooglePlacesComponent } from './components/google-places/google-places.component';
import { HistoryCommentsComponent } from './components/history-comments/history-comments.component';
import { HtmlModalComponent } from './components/html-modal/html-modal.component';
import { JsonModalComponent } from './components/json-modal/json-modal.component';
import { LabelManagerComponent } from './components/label-manager/label-manager.component';
import { MapComponent } from './components/map/map.component';
import { MapboxViewComponent } from './components/mapbox-view/mapbox-view.component';
import { MediaBrowserComponent } from './components/media-browser/media-browser.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { NetworkInviteModalComponent } from './components/network-invite-modal/network-invite-modal.component';
import { OnOffSwitchComponent } from './components/on-off-switch/on-off-switch.component';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PresetFiltersComponent } from './components/preset-filters/preset-filters.component';
import { PresetsComponent } from './components/presets/presets.component';
import { PriceDisplayComponent } from './components/price-display/price-display.component';
import { PriceFormComponent } from './components/price-form/price-form.component';
import { PriceInputComponent } from './components/price-input/price-input.component';
import { PriceComponent } from './components/price/price.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { QuantityControlComponent } from './components/quantity-control/quantity-control.component';
import { QuantityInputComponent } from './components/quantity-input/quantity-input.component';
import { RelatedEnvelopesComponent } from './components/related-envelopes/related-envelopes.component';
import { RelatedProductsComponent } from './components/related-products/related-products.component';
import { ScopeIconComponent } from './components/scope-icon/scope-icon.component';
// import { ScrollspyComponent } from './components/scrollspy/scrollspy.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { SelectedColumnFiltersComponent } from './components/selected-column-filters/selected-column-filters.component';
import { SelectedFiltersComponent } from './components/selected-filters/selected-filters.component';
import { SelectizeEmailsComponent } from './components/selectize-emails/selectize-emails.component';
import { SelectizeComponent } from './components/selectize/selectize.component';
import { SignersModalsComponent } from './components/signers-modals/signers-modals.component';
import { SingleInviteComponent } from './components/single-invite/single-invite.component';
import { SlotsDetailsComponent } from './components/slots-details/slots-details.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SpreadsheetParserComponent } from './components/spreadsheet-parser/spreadsheet-parser.component';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { TableDetailComponent } from './components/table-detail/table-detail.component';
import { TextInPlaceComponent } from './components/text-in-place/text-in-place.component';
import { ThSortableComponent } from './components/th-sortable/th-sortable.component';
import { TimestampComponent } from './components/timestamp/timestamp.component';
import { TrackrecordComponent } from './components/trackrecord/trackrecord.component';
import { VisualTrackerComponent } from './components/visual-tracker/visual-tracker.component';
import { VolumeCalculatorGenericComponent } from './components/volume-calculator-generic/volume-calculator-generic.component';
import { VolumeCalculatorComponent } from './components/volume-calculator/volume-calculator.component';
import { AgCheckboxDirective } from './directives/ag-checkbox.directive';
import { AgColumnDirective } from './directives/ag-column.directive';
import { AgContainerDirective } from './directives/ag-container.directive';
import { AgMultiselectionDirective } from './directives/ag-multiselection.directive';
import { AgOptionDirective } from './directives/ag-option.directive';
import { AgSelectDirective } from './directives/ag-select.directive';
import { AgSelectallDirective } from './directives/ag-selectall.directive';
import { ArrayMinlengthDirective } from './directives/array-minlength.directive';
import { AssetIncludeDirective } from './directives/asset-include.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ClickStopDirective } from './directives/click-stop.directive';
import { ClipboardDirective } from './directives/clipboard.directive';
import { ConfirmDirective } from './directives/confirm.directive';
import { CountDownDirective } from './directives/count-down.directive';
import { FileTypeDirective } from './directives/file-type.directive';
import { FutureTimeDirective } from './directives/future-time.directive';
import { HasErrorDirective } from './directives/has-error.directive';
import { MatchesDirective } from './directives/matches.directive';
import { MaxDirective } from './directives/max.directive';
import { MinDirective } from './directives/min.directive';
import { ModalFocusDirective } from './directives/modal-focus.directive';
import { ResizeDirective } from './directives/resize.directive';
import { ScrollGroupDirective } from './directives/scroll-group.directive';
import { ScrollItemDirective } from './directives/scroll-item.directive';
import { SortableDirective } from './directives/sortable.directive';
import { TimeRangeValueDirective } from './directives/time-range-value.directive';
import { TrackedControlDirective } from './directives/tracked-control.directive';
import { TrackedGroupDirective } from './directives/tracked-group.directive';
import { ValidDNIDirective } from './directives/valid-dni.directive';
import { ValidFiscalIdDirective } from './directives/valid-fiscalId.directive';
import { WithRouteDirective } from './directives/with-route.directive';
import { CmCodePipe } from './pipes/cm-code.pipe';
import { ColumnFilterPipe } from './pipes/column-filter.pipe';
import { FilesizePipe } from './pipes/filesize.pipe';
import { HighlighterPipe } from './pipes/highlighter.pipe';
import { LineBreakPipe } from './pipes/line-break.pipe';
import { LiteralsPipe } from './pipes/literals.pipe';
import { LocationStringPipe } from './pipes/location-string.pipe';
import { PricePipe } from './pipes/price.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { TrustStyleUrlPipe } from './pipes/trust-style-url.pipe';
import { TypeofPipe } from './pipes/typeof.pipe';
import { UserMessagePipe } from './pipes/user-message.pipe';
import { FlashService } from './services/flash.service';
import { LeaveGuard } from './services/leave-guard.service';
import { RouteSpyService } from './services/route-spy.service';
defineLocale('es', esLocale);

@NgModule({
  imports: [
    AlertModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule,
    CollapseModule.forRoot(),
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    NgPipesModule,
    NgxDocViewerModule,
    NgxJsonViewerModule,
    PopoverModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          // ['blockquote', 'code-block'],

          // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          // [{ 'direction': 'rtl' }],                         // text direction

          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],

          ['clean']                                         // remove formatting button
        ]
      }
    }),
    RouterModule,
    TabsModule.forRoot(),
    TooltipModule,
    ProgressbarModule.forRoot(),
    TranslateModule.forChild(),
    NgxMaskDirective,
    NgxMaskPipe,
    PasswordStrengthMeterModule.forRoot(DEFAULT_PSM_OPTIONS),
  ],
  declarations: [
    TrustStyleUrlPipe,
    ScrollGroupDirective,
    ScrollItemDirective,
    ModalFocusDirective,
    WithRouteDirective,
    ClickOutsideDirective,
    ClickStopDirective,
    ProgressCircleComponent,
    FileInputComponent,
    FileTypeDirective,
    MinDirective,
    MaxDirective,
    SearchInputComponent,
    ConfirmComponent,
    HasErrorDirective,
    ErrorListComponent,
    AgSelectDirective,
    AgOptionDirective,
    ThSortableComponent,
    SortableDirective,
    MultiSelectComponent,
    SpinnerComponent,
    ArrayMinlengthDirective,
    OnOffSwitchComponent,
    AgMultiselectionDirective,
    AgCheckboxDirective,
    AgSelectallDirective,
    MatchesDirective,
    AgContainerDirective,
    AgColumnDirective,
    TrackedControlDirective,
    AssetIncludeDirective,
    TrackedGroupDirective,
    LocationStringPipe,
    PriceDisplayComponent,
    PricePipe,
    QuantityControlComponent,
    PaginatorComponent,
    SelectizeComponent,
    SelectizeEmailsComponent,
    TimeRangeValueDirective,
    FutureTimeDirective,
    MediaBrowserComponent,
    MapboxViewComponent,
    ValidFiscalIdDirective,
    GooglePlacesComponent,
    RelatedProductsComponent,
    GmapViewComponent,
    FlagComponent,
    CertificatesTableComponent,
    CertificatesButtonComponent,
    FiscalIdComponent,
    EditInPlaceComponent,
    CompanyInfoComponent,
    AddPhoneComponent,
    VisualTrackerComponent,
    PriceComponent,
    FilterSetComponent,
    SelectedFiltersComponent,
    FilterListComponent,
    FilterSelectComponent,
    TimestampComponent,
    ColumnFilterComponent,
    BouncerComponent,
    ScopeIconComponent,
    DateRangeComponent,
    UserMessagePipe,
    BatchFinderComponent,
    // ScrollspyComponent,
    DateMdComponent,
    CompanyLogoComponent,
    CompanyTableComponent,
    MapComponent,
    BatchDetailsComponent,
    BatchDestinationComponent,
    TableDetailComponent,
    EnvelopeStatusComponent,
    EasyStepsComponent,
    ConnectorComponent,
    PriceFormComponent,
    CmCodePipe,
    OrdersTableComponent,
    AttachmentsComponent,
    LabelManagerComponent,
    CellphoneVerificationComponent,
    LineBreakPipe,
    FilePreviewComponent,
    PresetsComponent,
    RelatedEnvelopesComponent,
    EnvelopesModalsComponent,
    SignersModalsComponent,
    CommercialZonesManagerComponent,
    TrackrecordComponent,
    ConfirmDirective,
    VolumeCalculatorComponent,
    FilesizePipe,
    CountDownDirective,
    PriceInputComponent,
    ColorSelectorComponent,
    FilterDateRangeComponent,
    SpreadsheetParserComponent,
    DoaComponent,
    FormReuseComponent,
    QuantityInputComponent,
    SafeUrlPipe,
    ReplacePipe,
    VolumeCalculatorGenericComponent,
    StripHtmlPipe,
    GaugeComponent,
    StatusIndicatorComponent,
    PresetFiltersComponent,
    ClipboardDirective,
    DividerLineComponent,
    AssignmentSelectComponent,
    JsonModalComponent,
    TypeofPipe,
    FilterMultipleListComponent,
    ResizeDirective,
    LiteralsPipe,
    HistoryCommentsComponent,
    HighlighterPipe,
    TextInPlaceComponent,
    ExpandableContentComponent,
    HtmlModalComponent,
    SafeHtmlPipe,
    SingleInviteComponent,
    BulkInviteComponent,
    NetworkInviteModalComponent,
    ValidDNIDirective,
    CommercialZonesModalComponent,
    SelectedColumnFiltersComponent,
    ColumnFilterPipe,
    SlotsDetailsComponent,
    SplitPipe,
    FileManagerModalComponent
  ],
  exports: [
    AddPhoneComponent,
    AgCheckboxDirective,
    AgColumnDirective,
    AgContainerDirective,
    AgMultiselectionDirective,
    AgOptionDirective,
    AgSelectallDirective,
    AgSelectDirective,
    AlertModule,
    ArrayMinlengthDirective,
    AssetIncludeDirective,
    AttachmentsComponent,
    BatchDestinationComponent,
    BatchDetailsComponent,
    BatchFinderComponent,
    BouncerComponent,
    BsDatepickerModule,
    BsDropdownModule,
    CellphoneVerificationComponent,
    CertificatesButtonComponent,
    CertificatesTableComponent,
    ClickOutsideDirective,
    ClickStopDirective,
    CollapseModule,
    ColorSelectorComponent,
    ColumnFilterComponent,
    CommercialZonesManagerComponent,
    CompanyInfoComponent,
    CompanyLogoComponent,
    CompanyTableComponent,
    ConfirmComponent,
    ConfirmDirective,
    ConnectorComponent,
    CountDownDirective,
    DateMdComponent,
    DateRangeComponent,
    DoaComponent,
    FormReuseComponent,
    EasyStepsComponent,
    EditInPlaceComponent,
    EnvelopesModalsComponent,
    EnvelopeStatusComponent,
    ErrorListComponent,
    FileInputComponent,
    FilePreviewComponent,
    FilesizePipe,
    FileTypeDirective,
    FilterDateRangeComponent,
    FilterListComponent,
    FilterMultipleListComponent,
    FilterSelectComponent,
    FilterSetComponent,
    FiscalIdComponent,
    FlagComponent,
    FutureTimeDirective,
    GmapViewComponent,
    GooglePlacesComponent,
    HasErrorDirective,
    LabelManagerComponent,
    LocationStringPipe,
    MapboxViewComponent,
    MapComponent,
    MatchesDirective,
    MaxDirective,
    MediaBrowserComponent,
    MinDirective,
    ModalFocusDirective,
    ModalModule,
    MultiSelectComponent,
    // NgxMaskModule,
    OnOffSwitchComponent,
    OrdersTableComponent,
    PaginatorComponent,
    PopoverModule,
    PresetsComponent,
    PriceComponent,
    PriceDisplayComponent,
    PriceFormComponent,
    PriceInputComponent,
    PricePipe,
    ProgressCircleComponent,
    QuantityControlComponent,
    QuantityInputComponent,
    RelatedEnvelopesComponent,
    RelatedProductsComponent,
    ScopeIconComponent,
    ScrollGroupDirective,
    ScrollItemDirective,
    // ScrollspyComponent,
    SearchInputComponent,
    SelectedFiltersComponent,
    SelectizeComponent,
    SelectizeEmailsComponent,
    SignersModalsComponent,
    SortableDirective,
    SpinnerComponent,
    SpreadsheetParserComponent,
    TableDetailComponent,
    TabsModule,
    ThSortableComponent,
    TimeRangeValueDirective,
    TimestampComponent,
    TooltipModule,
    TrackedControlDirective,
    TrackedGroupDirective,
    TrackrecordComponent,
    TranslateModule,
    TrustStyleUrlPipe,
    UserMessagePipe,
    ValidFiscalIdDirective,
    VisualTrackerComponent,
    VolumeCalculatorComponent,
    WithRouteDirective,
    SafeUrlPipe,
    ProgressbarModule,
    ReplacePipe,
    QuillModule,
    VolumeCalculatorGenericComponent,
    GaugeComponent,
    StatusIndicatorComponent,
    NgxDocViewerModule,
    ClipboardDirective,
    DividerLineComponent,
    AssignmentSelectComponent,
    JsonModalComponent,
    TypeofPipe,
    ResizeDirective,
    PresetFiltersComponent,
    LiteralsPipe,
    HistoryCommentsComponent,
    HighlighterPipe,
    TextInPlaceComponent,
    ExpandableContentComponent,
    HtmlModalComponent,
    SafeHtmlPipe,
    NetworkInviteModalComponent,
    ValidDNIDirective,
    CommercialZonesModalComponent,
    SelectedColumnFiltersComponent,
    ColumnFilterPipe,
    SlotsDetailsComponent,
    SplitPipe,
    NgxMaskDirective,
    NgxMaskPipe,
    StripHtmlPipe,
    PasswordStrengthMeterModule
  ],
  providers: [
    FlashService,
    RouteSpyService,
    LeaveGuard,
    LocationStringPipe,
    provideNgxMask({
      patterns: {
        ...NgxMaskConfig.patterns, // Extend existing patterns
        'K': { pattern: new RegExp('\[0-9kK]') } // RUT Support
      }
    })
  ]
})
export class UiModule {
  constructor(private bsLocaleService: BsLocaleService) {
    this.bsLocaleService.use('es');
  }
}
