const EMAIL_SUPPORT = 'contacto@agrology.com';

export default {
  WALKTHROUGH: {
    GENERAL: {
      INTRO_1: '<h5>Welcome to <b>Agrology</b>!</h5><p>Before we start, we would like to show you the main elements of our platform.</p><p class="text-right">Ready?...</p>',
      INTRO_5: '<h5>Main navigation bar</h5><p>It allows you to access the different sections of Agrology.</p>',
      INTRO_7: '<h5>That is all!</h5><p>From time to time, we will share news about the product, such as new features and improvements.</p><p>If you have any doubt, you can send an email to <a href="mailto:' + EMAIL_SUPPORT + '">' + EMAIL_SUPPORT + '</a></p>'
    }
  },
  HEADER: {
    BASIC_ACTIVATION: '<b>Your Company cannot operate.</b> We may need to validate some things. Get in touch with <a href="mailto:' + EMAIL_SUPPORT + '">support team</a>.',
    VERIFICATION_FAILED: '<h1>Your e-mail could not be verified<small><br>Please, contact our <a href="mailto:' + EMAIL_SUPPORT + '">support team</a>.</small></h1>'
  },
  COMPANIES: {
    ADD_COMPANY_HELP: 'If you have questions, contact <a href=\'mailto:' + EMAIL_SUPPORT + '\'>our support team</a>.'
  },
  ORDER_FORM: {
    SCOPE: {
      NO_OPEN_HELP: 'Your Company <b>cannot operate in the Public market</b>. We may need to validate some things. If interested, get in touch with us at <a href="mailto:' + EMAIL_SUPPORT + '">' + EMAIL_SUPPORT + '</a>.'
    }
  },
  LANDING: {
    SECONDARY: 'Welcome to the digital<br>business platform.',
    TAGLINE: 'Potenciando juntos <span class="brand-color">al campo argentino</span>'
  },
  NOT_FOUND: {
    DESCRIPTION: 'If you were redirected to this page by the platform, please contact our <a href="mailto:' + EMAIL_SUPPORT + '">support team</a>.'
  },
  INTEGRATIONS: {
    API_DOCUMENTATION_HELP: 'To access our API documentation, contact our <a href="mailto:' + EMAIL_SUPPORT + '">support team</a>.'
  },
  CLIENT_CREDENTIALS: {
    OAUTH: {
      TITLE: 'Authorize the connection between <b>{{company}}</b> and your <b>Agrology</b> account.',
      DESCRIPTION: 'Do you agree to give them access to your account? Allow <b>{{company}}</b> to access your Agrology account and enjoy an integrated and enhanced experience. Your information will be secure and we will use only the necessary data.'
    }
  }
};
