const EMAIL_SUPPORT = 'contacto@agrology.com';

export default {
  WALKTHROUGH: {
    GENERAL: {
      INTRO_1: '<h5>Bienvenido a <b>Agrology</b>!</h5><p>Antes de empezar, nos gustaría indicarte los principales elementos de nuestra plataforma.</p><p class="text-right">¿Comenzamos?...</p>',
      INTRO_5: '<h5>Barra de navegación principal</h5><p>Te permite acceder a las distintas secciones de Agrology.</p>',
      INTRO_7: '<h5>¡Eso es todo!</h5><p>De vez en cuando, vamos a ir comunicándote novedades en el producto, como nuevas funcionalidades y mejoras.</p><p>Ante cualquier duda podés escribirnos a <a href="mailto:' + EMAIL_SUPPORT + '">' + EMAIL_SUPPORT + '</a></p>'
    }
  },
  HEADER: {
    BASIC_ACTIVATION: '<b>Tu Empresa aún no puede operar.</b> Es probable que necesitemos validar algunas cosas. Ponete en contacto con <a href="mailto:' + EMAIL_SUPPORT + '">nuestro equipo de soporte</a>.',
    VERIFICATION_FAILED: '<h1>No se pudo verificar tu e-mail<small><br>Por favor, ponete en contacto con <a href="mailto:' + EMAIL_SUPPORT + '">nuestro equipo de soporte</a>.</small></h1>'
  },
  COMPANIES: {
    ADD_COMPANY_HELP: 'Si tenés dudas, contactá a <a href=\'mailto:' + EMAIL_SUPPORT + '\'>nuestro equipo de soporte</a>.'
  },
  ORDER_FORM: {
    SCOPE: {
      NO_OPEN_HELP: 'Tu Empresa <b>no puede operar en el mercado Abierto</b>. Es probable que necesitemos validar algunas cosas. Si estás interesado, ponete en contacto con nosotros a <a href="mailto:' + EMAIL_SUPPORT + '">' + EMAIL_SUPPORT + '</a>.'
    }
  },
  LANDING: {
    SECONDARY: 'Bienvenido a la plataforma<br>digital de negocios.',
    TAGLINE: 'Potenciando juntos <span class="brand-color">al campo argentino</span>'
  },
  NOT_FOUND: {
    DESCRIPTION: 'Si fuiste redireccionado a esta página por la plataforma, por favor contacta a nuestro <a href="mailto:info@agree.ag">equipo de soporte</a>.'
  },
  INTEGRATIONS: {
    API_DOCUMENTATION_HELP: 'Para acceder a la documentación de nuestra API, ponete en contacto con nuestro <a href="mailto:' + EMAIL_SUPPORT + '">equipo de soporte</a>.'
  },
  CLIENT_CREDENTIALS: {
    OAUTH: {
      TITLE: 'Autoriza la conexión entre <b>{{company}}</b> y tu cuenta de <b>Agrology</b>.',
      DESCRIPTION: '¿Estás de acuerdo en darle acceso a tu cuenta? Permite que <b>{{company}}</b> acceda a tu cuenta de Agrology y disfruta de una experiencia integrada y mejorada. Tu información estará segura y utilizaremos únicamente los datos necesarios.'
    }
  }
};
