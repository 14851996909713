import { Component, EventEmitter, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';

import { MetaDataField } from '../../../services/metadata.service';

@Component({
  selector: 'ag-spreadsheet-parser',
  templateUrl: './spreadsheet-parser.component.html',
  styleUrls: ['./spreadsheet-parser.component.scss']
})
export class SpreadsheetParserComponent implements OnDestroy {

  @ViewChild('modal', { static: true }) private readonly modal: TemplateRef<any>;

  /** Triggered on step completed. */
  @Output() readonly detection = new EventEmitter();

  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;
  public jsonData: {
    data: any;
    formatted: { [key: string]: MetaDataField };
  };

  private modalRef: BsModalRef;
  private modalSub: Subscription;

  constructor(
    private modalService: BsModalService
  ) { }

  public parse(file: File): void {
    const extension: string = file.name.toLowerCase().split('.').pop();
    const validExtensions = ['xls', 'xlsx', 'csv', 'xlsm', 'xlsb', 'ods'];

    if (validExtensions.includes(extension)) {
      /* wire up file reader */
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const wb: XLSX.WorkBook = XLSX.read(e.target.result as ArrayBuffer);

        this.parseXLS({
          file: file,
          workbook: wb
        });

        /* grab first sheet */
        // const wsname: string = wb.SheetNames[0];
        // const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        // console.log(XLSX.utils.sheet_to_json(ws));
      };
      reader.readAsArrayBuffer(file);
    }
  }

  private parseXLS(spreadsheet: {
    file: File,
    workbook: XLSX.WorkBook
  }) {
    const { workbook } = spreadsheet;

    // Iterates over all known formats
    for (const format in KnownFormats) {
      let result = this.read(workbook, KnownFormats[format]);
      if (result) {
        if (!this.jsonData) {
          this.jsonData = { data: {}, formatted: {} };
        }
        Object.assign(this.jsonData.data, result.data);
        Object.assign(this.jsonData.formatted, result.formatted);
      }
    }

    if (this.jsonData) {
      this.openModal(this.modal);
    }
  }

  public apply(): void {
    this.detection.emit(this.jsonData.formatted);
    this.closeModal();
  }

  private read(workbook: XLSX.WorkBook, formatMap: XLSmapping[]): any {
    let match: boolean;
    let atLeastOneMatch: boolean;
    let parsed: { [key: string]: any };
    let formatted: { [key: string]: MetaDataField };

    for (let index = 0; index < workbook.SheetNames.length; index++) {
      const wsname = workbook.SheetNames[index];
      const ws: XLSX.WorkSheet = workbook.Sheets[wsname];

      atLeastOneMatch = false;

      if (ws) {
        parsed = {};
        formatted = {};

        let skipRestOfTab: boolean = false;

        formatMap.forEach(data => {
          if (!skipRestOfTab) {
            const cell = ws[data.cell];

            if (cell) {
              let value = cell.v;

              if (data.valid) {
                const knownSheet = data.valid(cell.v);
                match = knownSheet;
                skipRestOfTab = !knownSheet;
              } else {
                switch (data.field.input.type) {
                  case 'number':
                    if (typeof value === 'string') value = value.replace(/[^\d,.-]/g, '');
                    value = parseFloat(value);
                    if (isNaN(value)) value = undefined;
                    break;

                  case 'text':
                    value = String(value).trim();
                    if (value === '' || value === '-') value = undefined;
                    break;

                  case 'date':
                    let hours = 0;
                    let minutes = 0;
                    // Not sure if this is needed
                    // if (this.company.market.offset) {
                    //   const tempOffset = this.company.market.offset.split(':');
                    //   if (tempOffset[0]) hours = parseInt(tempOffset[0]);
                    //   if (tempOffset[1]) minutes = parseInt(tempOffset[1]);
                    // }
                    value = new Date(Date.UTC(0, 0, value, hours, minutes));
                    if (isNaN(value.getTime())) value = undefined;
                    break;

                  default:
                    break;
                }

                if (typeof value != 'undefined' && cell.w !== '#N/A') {
                  atLeastOneMatch = true;
                  parsed[data.field.label || data.key] = value;
                  formatted[data.key] = { ...data.field, value };
                }
              }
            } else if (data.valid) {
              skipRestOfTab = true;
            }
          }
        });
      }

      if (match && atLeastOneMatch) break;
    }

    return match ? {
      data: parsed,
      formatted: formatted
    } : undefined;
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, { class: c });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      // this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
  }
}

class XLSmapping {
  key: string;
  cell: string;
  field?: MetaDataField;
  valid?: Function
}

class KnownFormats {
  static ADAMA_AR_scoring_Tab1: XLSmapping[] = [
    {
      key: "validator",
      cell: "B4",
      valid: (value) => {
        return value === 'ADAMA ARGENTINA SA';
      }
    },
    {
      key: "seller",
      cell: "F16",
      field: {
        order: 101,
        label: "Vendedor",
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "adamacredit",
      cell: "C22",
      field: {
        order: 102,
        label: "Crédito Adama (miles)",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "cofacecredit",
      cell: "D22",
      field: {
        order: 103,
        label: "Crédito Coface (miles)",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "balancesheetclosing",
      cell: "E22",
      field: {
        order: 104,
        label: "Cierre de balance",
        important: true,
        input: {
          type: "date"
        }
      }
    },
    {
      key: "warranty",
      cell: "F22",
      field: {
        order: 105,
        label: "Garantía (miles)",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "observations1",
      cell: "B25",
      field: {
        order: 106,
        label: "Observaciones 1",
        // important: true,
        input: {
          type: "text",
          maxlength: 128
        }
      }
    },
    {
      key: "observations2",
      cell: "B26",
      field: {
        order: 107,
        label: "Observaciones 2",
        // important: true,
        input: {
          type: "text",
          maxlength: 128
        }
      }
    },
    {
      key: "historiccreditlimitdate",
      cell: "B31",
      field: {
        order: 108,
        label: "Límite de Crédito Histórico (fecha)",
        // important: true,
        input: {
          type: "date"
        }
      }
    },
    {
      key: "historiccreditlimit",
      cell: "C31",
      field: {
        order: 109,
        label: "Límite de Crédito Histórico",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    }
  ];

  static ADAMA_AR_scoring2_Tab1: XLSmapping[] = [
    {
      key: "validator",
      cell: "B3",
      valid: (value) => {
        return value === 'ADAMA ARGENTINA S.A.';
      }
    },
    {
      key: "seller",
      cell: "H10",
      field: {
        order: 101,
        label: "Vendedor",
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "adamacredit",
      cell: "F16",
      field: {
        order: 102,
        label: "Crédito Adama (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "opencredit",
      cell: "C16",
      field: {
        order: 103,
        label: "Open Credit (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "bartercredit",
      cell: "D16",
      field: {
        order: 104,
        label: "Barter Credit (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "3rdpartycheckscredit",
      cell: "E16",
      field: {
        order: 105,
        label: "Third party's checks (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "cofacecredit",
      cell: "G16",
      field: {
        order: 106,
        label: "Crédito Coface (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "balancesheetclosing",
      cell: "H16",
      field: {
        order: 107,
        label: "Cierre de balance",
        important: true,
        input: {
          type: "date"
        }
      }
    },
    {
      key: "warranty",
      cell: "I16",
      field: {
        order: 108,
        label: "Garantía (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "observations",
      cell: "C18",
      field: {
        order: 109,
        label: "Observaciones",
        // important: true,
        input: {
          type: "text",
          maxlength: 128
        }
      }
    },
    {
      key: "historiccreditlimitdate",
      cell: "B24",
      field: {
        order: 111,
        label: "Límite de Crédito Histórico (fecha)",
        // important: true,
        input: {
          type: "date"
        }
      }
    },
    {
      key: "historiccreditlimitopen",
      cell: "C24",
      field: {
        order: 112,
        label: "Límite de Crédito Histórico (Open)",
        // important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "historiccreditlimitbarter",
      cell: "D24",
      field: {
        order: 113,
        label: "Límite de Crédito Histórico (Barter)",
        // important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "historiccreditlimit3rdchecks",
      cell: "E24",
      field: {
        order: 114,
        label: "Límite de Crédito Histórico (3er Party's checks)",
        // important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "historiccreditlimit",
      cell: "F24",
      field: {
        order: 115,
        label: "Límite de Crédito Histórico",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "historiccreditlimitcoface",
      cell: "G24",
      field: {
        order: 116,
        label: "Límite de Crédito Histórico (COFACE)",
        // important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "historiccreditlimitbalancedate",
      cell: "H24",
      field: {
        order: 117,
        label: "Límite de Crédito Histórico (Balance date)",
        // important: true,
        input: {
          type: "date"
        }
      }
    },
    {
      key: "historiccreditlimitwarranty",
      cell: "I24",
      field: {
        order: 118,
        label: "Límite de Crédito Histórico (Garantía)",
        // important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    }
  ];

  static ADAMA_PY_scoring_Tab1: XLSmapping[] = [
    {
      key: "validator",
      cell: "B3",
      valid: (value) => {
        return value === 'Adama Agriculture Paraguay S.A.';
      }
    },
    ...KnownFormats.ADAMA_AR_scoring2_Tab1.slice(1) // Copia los elementos desde la posición 1
  ];

  static ADAMA_AR_scoring_Tab2: XLSmapping[] = [
    {
      key: "validator",
      cell: "B46",
      valid: (value) => {
        return value === 'Credit limit advised > = credit limit   ';
      }
    },
    {
      key: "equity2_antepenultimateyear",
      cell: "E10",
      field: {
        order: 201,
        label: "Equity/2 Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "equity2_penultimateyear",
      cell: "F10",
      field: {
        order: 202,
        label: "Equity/2 Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "equity2_lastyear",
      cell: "G10",
      field: {
        order: 203,
        label: "Equity/2 Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "annualsales_antepenultimateyear",
      cell: "E11",
      field: {
        order: 204,
        label: "Annual Sales Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "annualsales_penultimateyear",
      cell: "F11",
      field: {
        order: 205,
        label: "Annual Sales Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "annualsales_lastyear",
      cell: "G11",
      field: {
        order: 206,
        label: "Annual Sales Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "operationalprofit_antepenultimateyear",
      cell: "E12",
      field: {
        order: 207,
        label: "Operational Profit Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "operationalprofit_penultimateyear",
      cell: "F12",
      field: {
        order: 208,
        label: "Operational Profit Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "operationalprofit_lastyear",
      cell: "G12",
      field: {
        order: 209,
        label: "Operational Profit Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "adamanetsales_antepenultimateyear",
      cell: "E13",
      field: {
        order: 210,
        label: "Adama Net Sales Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "adamanetsales_penultimateyear",
      cell: "F13",
      field: {
        order: 211,
        label: "Adama Net Sales Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "adamanetsales_lastyear",
      cell: "G13",
      field: {
        order: 212,
        label: "Adama Net Sales Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "creditlimit",
      cell: "F21",
      field: {
        order: 213,
        label: "Credit limit (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "qualificationnosis",
      cell: "I36",
      field: {
        order: 214,
        label: "Qualification NOSIS",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 1
        }
      }
    },
    {
      key: "points",
      cell: "I46",
      field: {
        order: 215,
        label: "Points",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          decimalPipe: "1.0-1"
        }
      }
    }
  ];

  static ADAMA_AR_scoring2_Tab2: XLSmapping[] = [
    {
      key: "validator",
      cell: "B48",
      valid: (value) => {
        return value === 'Credit limit advised > = credit limit   ';
      }
    },
    {
      key: "equity2_antepenultimateyear",
      cell: "E9",
      field: {
        order: 201,
        label: "Equity/2 Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "equity2_penultimateyear",
      cell: "F9",
      field: {
        order: 202,
        label: "Equity/2 Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "equity2_lastyear",
      cell: "G9",
      field: {
        order: 203,
        label: "Equity/2 Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "annualsales_antepenultimateyear",
      cell: "E10",
      field: {
        order: 204,
        label: "Annual Sales Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "annualsales_penultimateyear",
      cell: "F10",
      field: {
        order: 205,
        label: "Annual Sales Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "annualsales_lastyear",
      cell: "G10",
      field: {
        order: 206,
        label: "Annual Sales Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "operationalprofit_antepenultimateyear",
      cell: "E11",
      field: {
        order: 207,
        label: "Operational Profit Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "operationalprofit_penultimateyear",
      cell: "F11",
      field: {
        order: 208,
        label: "Operational Profit Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "operationalprofit_lastyear",
      cell: "G11",
      field: {
        order: 209,
        label: "Operational Profit Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "adamanetsales_antepenultimateyear",
      cell: "E12",
      field: {
        order: 210,
        label: "Adama Net Sales Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "adamanetsales_penultimateyear",
      cell: "F12",
      field: {
        order: 211,
        label: "Adama Net Sales Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "adamanetsales_lastyear",
      cell: "G12",
      field: {
        order: 212,
        label: "Adama Net Sales Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "creditlimit",
      cell: "F20",
      field: {
        order: 213,
        label: "Credit limit (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "qualificationnosis",
      cell: "I38",
      field: {
        order: 214,
        label: "Qualification NOSIS",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 1
        }
      }
    },
    {
      key: "points",
      cell: "I48",
      field: {
        order: 215,
        label: "Points",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          decimalPipe: "1.0-1"
        }
      }
    }
  ];

  static ADAMA_PY_scoring_Tab2: XLSmapping[] = [
    {
      key: "validator",
      cell: "B48",
      valid: (value) => {
        return value === 'Credit limit advised > = credit limit   ';
      }
    },
    {
      key: "equity2_antepenultimateyear",
      cell: "E9",
      field: {
        order: 201,
        label: "Equity/2 Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "equity2_penultimateyear",
      cell: "F9",
      field: {
        order: 202,
        label: "Equity/2 Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "equity2_lastyear",
      cell: "G9",
      field: {
        order: 203,
        label: "Equity/2 Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "annualsales_antepenultimateyear",
      cell: "E10",
      field: {
        order: 204,
        label: "Annual Sales Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "annualsales_penultimateyear",
      cell: "F10",
      field: {
        order: 205,
        label: "Annual Sales Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "annualsales_lastyear",
      cell: "G10",
      field: {
        order: 206,
        label: "Annual Sales Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "operationalprofit_antepenultimateyear",
      cell: "E11",
      field: {
        order: 207,
        label: "Operational Profit Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "operationalprofit_penultimateyear",
      cell: "F11",
      field: {
        order: 208,
        label: "Operational Profit Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "operationalprofit_lastyear",
      cell: "G11",
      field: {
        order: 209,
        label: "Operational Profit Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "adamanetsales_antepenultimateyear",
      cell: "E12",
      field: {
        order: 210,
        label: "Adama Net Sales Antepenultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "adamanetsales_penultimateyear",
      cell: "F12",
      field: {
        order: 211,
        label: "Adama Net Sales Penultimate Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "adamanetsales_lastyear",
      cell: "G12",
      field: {
        order: 212,
        label: "Adama Net Sales Last Year (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "creditlimit",
      cell: "F20",
      field: {
        order: 213,
        label: "Credit limit (miles)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          left: "USD"
        }
      }
    },
    {
      key: "qualificationnosis",
      cell: "I38",
      field: {
        order: 214,
        label: "Qualification NOSIS",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 1
        }
      }
    },
    {
      key: "points",
      cell: "I48",
      field: {
        order: 215,
        label: "Points",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          decimalPipe: "1.0-1"
        }
      }
    }
  ];

  static UPL_AR_scoring: XLSmapping[] = [
    {
      key: "validator",
      cell: "A23",
      valid: (value) => {
        return value === 'Customer Code';
      }
    },
    {
      key: "customercode",
      cell: "B8",
      field: {
        label: "Customer Code",
        important: true,
        input: {
          type: "number",
          placeholder: "0000000",
          step: 1
        }
      }
    },
    {
      key: "category",
      cell: "B21",
      field: {
        label: "Category",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "companycode",
      cell: "D24",
      field: {
        label: "Company Code",
        input: {
          type: "number",
          placeholder: "0000",
          step: 1
        }
      }
    },
    {
      key: "uplyears",
      cell: "F24",
      field: {
        label: "Relationship with UPL",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.1,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "Years"
        }
      }
    },
    {
      key: "marketyears",
      cell: "G24",
      field: {
        label: "Time of Company in the market",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.1,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "Years"
        }
      }
    },
    {
      key: "serasa_nosis",
      cell: "H24",
      field: {
        label: "SERASA/NOSIS - Local credit score",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 1000,
          step: 1
        }
      }
    },
    {
      key: "capacity",
      cell: "B11",
      field: {
        label: "Calculated capacity",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "yearsales",
      cell: "I24",
      field: {
        label: "Sales last year (With VAT)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.0-2"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "grossmargin",
      cell: "J24",
      field: {
        label: "GROSS MARGIN Last Fiscal Year",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.0-2"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "avgpayment",
      cell: "K24",
      field: {
        label: "Average payment Terms Sales - Last Fiscal Year",
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 1000,
          step: 1
        }
      }
    },
    {
      key: "overdue",
      cell: "L24",
      field: {
        label: "OVERDUE IF ANY",
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 9999999999,
          step: 1,
          decimalPipe: "1.0-2"
        }
      }
    },
    {
      key: "overduereason",
      cell: "M24",
      field: {
        label: "Reason for Overdue",
        input: {
          type: "text",
          maxlength: 64
        }
      }
    },
    {
      key: "currentorders",
      cell: "N24",
      field: {
        label: "CURRENT ORDERS IN HANDS",
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 9999999999,
          step: 1,
          decimalPipe: "1.0-2"
        }
      }
    },
    {
      key: "marginsales",
      cell: "O24",
      field: {
        label: "Gross margin/Sales plan",
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          percentPipe: "1.0-2"
        }
      }
    },
    {
      key: "avgpaymentcurrent",
      cell: "P24",
      field: {
        label: "Average payment Terms Sales - Current Fiscal Year",
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 1000,
          step: 1
        }
      }
    },
    {
      key: "guarantee",
      cell: "B17",
      field: {
        label: "Guarantee Amount",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 99999999,
          step: 1,
          decimalPipe: "1.0-2"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "guaranteetype",
      cell: "B18",
      field: {
        label: "Guarantee Type",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "bouncedchecks",
      cell: "B20",
      field: {
        label: "Bounced checks last 2 years",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          step: 1,
          decimalPipe: "1.0"
        }
      }
    },
    {
      key: "prevcreditline",
      cell: "B12",
      field: {
        label: "Previous Credit Line",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 99999999,
          step: 1,
          decimalPipe: "1.0-2"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "creaditinsurancelimit",
      cell: "B14",
      field: {
        label: "Credit Insurance Limit",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 99999999,
          step: 1,
          decimalPipe: "1.0-2"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "infodata",
      cell: "W24",
      field: {
        label: "Information data based on",
        important: true,
        input: {
          type: "date",
          placeholder: "dd/MM/yyyy"
        }
      }
    },
    {
      key: "annualturnover",
      cell: "X24",
      field: {
        label: "Annual Turnover",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 99999999,
          step: 1,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "supplierpaymentrotation",
      cell: "Y24",
      field: {
        label: "DPO = Supplier Payment Rotation",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 9999,
          step: 1,
          decimalPipe: "1.0-0"
        }
      }
    },
    {
      key: "ebitda",
      cell: "Z24",
      field: {
        label: "EBITDA Sales",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: -200,
          max: 200,
          step: 0.01,
          percentPipe: "1.0-2"
        }
      }
    },
    {
      key: "liquidity",
      cell: "AA24",
      field: {
        label: "Liquidity",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.2-2"
        }
      }
    },
    {
      key: "financialindexes",
      cell: "AB24",
      field: {
        label: "Financial indexes",
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "commercialreferences",
      cell: "AE24",
      field: {
        label: "Commercial References",
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "insurancecoverage",
      cell: "B15",
      field: {
        label: "Insurance coverage",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          step: 0.01,
          percentPipe: "1.0-0"
        }
      }
    },
    {
      key: "limitreason",
      cell: "B16",
      field: {
        label: "Reason for not covering the full limit",
        important: true,
        input: {
          type: "text",
          maxlength: 128
        }
      }
    },
    {
      key: "scoring",
      cell: "B9",
      field: {
        label: "Internal Score",
        input: {
          type: "number",
          placeholder: 0,
          min: -100,
          max: 100
        }
      }
    },
    {
      key: "rating",
      cell: "B10",
      field: {
        label: "Rating",
        input: {
          type: "text",
          maxlength: 2
        }
      }
    },
    {
      key: "paymentbehavior",
      cell: "AF24",
      field: {
        label: "Payment behavior",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "commercialref",
      cell: "AG24",
      field: {
        label: "Commercial references",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "zone",
      cell: "D2",
      field: {
        label: "Zone",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "rtv",
      cell: "D3",
      field: {
        label: "RTV",
        input: {
          type: "text",
          maxlength: 64
        }
      }
    },
    {
      key: "canalist",
      cell: "D4",
      field: {
        label: "C&C Analist",
        input: {
          type: "text",
          maxlength: 64
        }
      }
    }
  ];

  static UPL_MX_scoring_Tab1: XLSmapping[] = [
    {
      key: "validator",
      cell: "A18",
      valid: (value) => {
        return value === 'Credit Limit Information';
      }
    },
    {
      key: "customercode",
      cell: "C6",
      field: {
        label: "Customer Code",
        important: true,
        input: {
          type: "number",
          placeholder: "0000000",
          step: 1
        }
      }
    },
    {
      key: "uplyears",
      cell: "C7",
      field: {
        label: "Relationship with UPL",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.1,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "Years"
        }
      }
    },
    {
      key: "marketyears",
      cell: "G7",
      field: {
        label: "Time of Company in the market",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.1,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "Years"
        }
      }
    },
    {
      key: "grossmargin",
      cell: "D92",
      field: {
        label: "GROSS MARGIN Last Fiscal Year",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.0-2"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "avgpayment",
      cell: "C20",
      field: {
        label: "Average payment Terms Sales - Last Fiscal Year",
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 1000,
          step: 1
        }
      }
    },
    {
      key: "avgpaymentcurrent",
      cell: "G20",
      field: {
        label: "Average payment Terms Sales - Current Fiscal Year",
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 1000,
          step: 1
        }
      }
    },
    {
      key: "prevcreditline",
      cell: "G22",
      field: {
        label: "Previous Credit Line",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 99999999,
          step: 1,
          decimalPipe: "1.0-2"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "infodata",
      cell: "H1",
      field: {
        label: "Information data based on",
        important: true,
        input: {
          type: "date",
          placeholder: "dd/MM/yyyy"
        }
      }
    },
    {
      key: "annualturnover",
      cell: "D94",
      field: {
        label: "Annual Turnover",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 99999999,
          step: 1,
          decimalPipe: "1.0-0"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "supplierpaymentrotation",
      cell: "H97",
      field: {
        label: "DPO = Supplier Payment Rotation",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 9999,
          step: 1,
          decimalPipe: "1.0-0"
        }
      }
    },
    {
      key: "ebitda",
      cell: "H93",
      field: {
        label: "EBITDA Sales",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: -200,
          max: 200,
          step: 0.01,
          percentPipe: "1.0-2"
        }
      }
    },
    {
      key: "liquidity",
      cell: "H90",
      field: {
        label: "Liquidity",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.2-2"
        }
      }
    },
    {
      key: "insurancecoverage",
      cell: "G28",
      field: {
        label: "Insurance coverage",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          step: 0.01,
          percentPipe: "1.0-0"
        }
      }
    },
    {
      key: "scoring",
      cell: "G45",
      field: {
        label: "Internal Score",
        input: {
          type: "number",
          placeholder: 0,
          min: -100,
          max: 100
        }
      }
    },
    {
      key: "rating",
      cell: "H46",
      field: {
        label: "Rating",
        input: {
          type: "text",
          maxlength: 2
        }
      }
    },
    {
      key: "rtv",
      cell: "F6",
      field: {
        label: "RTV",
        input: {
          type: "text",
          maxlength: 64
        }
      }
    },
    {
      key: "canalist",
      cell: "D2",
      field: {
        label: "C&C Analist",
        input: {
          type: "text",
          maxlength: 64
        }
      }
    }
  ];

  static UPL_MX_scoring_Tab2: XLSmapping[] = [
    {
      key: "validator",
      cell: "A4",
      valid: (value) => {
        return value === 'El actual sobresaliente en USD';
      }
    },
    {
      key: "yearsales",
      cell: "C11",
      field: {
        label: "Sales last year (With VAT)",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          decimalPipe: "1.0-2"
        },
        addon: {
          right: "USD"
        }
      }
    },
    {
      key: "marginsales",
      cell: "C12",
      field: {
        label: "Gross margin/Sales plan",
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 100,
          step: 0.01,
          percentPipe: "1.0-2"
        }
      }
    },
    {
      key: "guaranteetype",
      cell: "C18",
      field: {
        label: "Guarantee Type",
        important: true,
        input: {
          type: "text",
          maxlength: 32
        }
      }
    },
    {
      key: "creaditinsurancelimit",
      cell: "C17",
      field: {
        label: "Credit Insurance Limit",
        important: true,
        input: {
          type: "number",
          placeholder: 0,
          min: 0,
          max: 99999999,
          step: 1,
          decimalPipe: "1.0-2"
        },
        addon: {
          right: "USD"
        }
      }
    }
  ];

  // static YOLO_MX_scoring: XLSmapping[] = [
  //   {
  //     key: "validator",
  //     cell: "A25",
  //     valid: (value) => {
  //       return value === 'Gestion Ambiental';
  //     }
  //   },
  //   {
  //     key: "situacion_actual",
  //     cell: "D26",
  //     field: {
  //       order: 10,
  //       label: "Situación actual",
  //       important: true,
  //       input: {
  //         type: "number",
  //         placeholder: 0,
  //         decimalPipe: "1.0-1"
  //       }
  //     }
  //   },
  //   {
  //     key: "destino_credito",
  //     cell: "H21",
  //     field: {
  //       order: 20,
  //       label: "Destino del crédito",
  //       important: true,
  //       input: {
  //         type: "number",
  //         placeholder: 0,
  //         decimalPipe: "1.0-1"
  //       }
  //     }
  //   },
  //   {
  //     key: "total",
  //     cell: "H26",
  //     field: {
  //       order: 30,
  //       label: "Score total",
  //       important: true,
  //       input: {
  //         type: "number",
  //         placeholder: 0,
  //         decimalPipe: "1.0-1"
  //       }
  //     }
  //   }
  // ];
}