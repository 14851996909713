import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Assignment, Participant, PARTICIPANTS } from '../../models/assignment.model';
import { isAnEmptyArray } from '../../../utilities/array';

@Component({
  selector: 'ag-assignment-select',
  templateUrl: './assignment-select.component.html',
  styleUrls: ['./assignment-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AssignmentSelectComponent),
    multi: true
  }]
})
export class AssignmentSelectComponent implements ControlValueAccessor {

  @Input() public multiple: boolean;
  @Input() public disabled: boolean;

  public map: ParticipantMap[];
  public roles = [
    { id: 0, value: 'FINANCIAL' },
    { id: 1, value: 'COMMERCIAL' }
  ];

  constructor() {
    this.map = [];

    PARTICIPANTS.forEach(participant => {
      this.map.push(new ParticipantMap({
        name: participant
      }));
    });
  }

  private mapFromValue(): void {
    const add = (assignment: Assignment) => {
      const index = this.map.findIndex(participantMap => participantMap.name === assignment.participant);

      if (assignment.roles?.length > 0) {
        assignment.roles.forEach(role => {
          const roleToAdd = this.roles.find(r => r.value === role);
          this.map[index].roles.push(roleToAdd);
        });
      }

      this.map[index].checked = true;
    };

    if (this.value) {
      if (Array.isArray(this.value)) this.value.forEach(assignment => add(assignment));
      else add(this.value);
    }
  }

  public update(updatedMap: ParticipantMap): void {
    let newValue: Assignment | Assignment[] = this.multiple ? [] : undefined;

    this.map.forEach(participantMap => {
      // If it's multiple selection, I'll take one
      if (!this.multiple && participantMap.name !== updatedMap.name) {
        participantMap.checked = false;
      }

      if (participantMap.checked) {
        // Add value if checked
        const assignment = new Assignment({
          participant: participantMap.name
        });

        if (participantMap.roles?.length > 0) {
          assignment.roles = participantMap.roles.map(value => value.value);
        }

        if (Array.isArray(newValue)) newValue.push(assignment);
        else newValue = assignment;
      }
    });

    if (isAnEmptyArray(newValue)) newValue = undefined;

    this._value = newValue;
    this.propagateChange(this._value);
  }

  // ngModel
  private _value: Assignment | Assignment[];
  public set value(v: Assignment | Assignment[]) {
    this._value = v;
    this.mapFromValue();
  }
  public get value(): Assignment | Assignment[] {
    return this._value;
  }
  private propagateChange = (_: any) => { };

  writeValue(value: Assignment | Assignment[]) {
    if (value !== undefined) {
      this.value = value;
    }
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(): void { }
}

class ParticipantMap {
  name: Participant;
  checked: boolean;
  roles: any[];

  constructor(data: Partial<ParticipantMap> = {}) {
    this.checked = false;
    this.roles = [];

    Object.assign(this, data);
  }
}