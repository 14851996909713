const EMAIL_SUPPORT = 'contacto@agrology.com';

export default {
  WALKTHROUGH: {
    GENERAL: {
      INTRO_1: '<h5>Bem vindo a <b>Agrology</b>!</h5><p>Antes de começar, gostaríamos de te mostrar os principais elementos da nossa plataforma.</p><p class="text-right">Vamos começar...</p>',
      INTRO_5: '<h5>Barra de navegação principal</h5><p>Te permite acessar as distintas seções do Agrology.</p>',
      INTRO_7: '<h5>Isso é tudo!</h5><p>De vez em quando, vamos nos comunicar e deixar você a par de novidades no produto, como novas funcionalidades e melhorias.</p><p>Se tiver alguma dúvida, pode nos escrever no <a href="mailto:' + EMAIL_SUPPORT + '">' + EMAIL_SUPPORT + '</a></p>'
    }
  },
  HEADER: {
    BASIC_ACTIVATION: '<b>Sua empresa ainda não pode operar.</b> Podemos precisar validar algumas coisas. Entre em contato com <a href="mailto:' + EMAIL_SUPPORT + '">nossa equipe de suporte</a>.',
    VERIFICATION_FAILED: '<h1>Não foi possível verificar seu e-mail<small><br>Por favor, entre em contato com <a href="mailto:' + EMAIL_SUPPORT + '">nossa equipe de suporte</a>.</small></h1>'
  },
  COMPANIES: {
    ADD_COMPANY_HELP: 'Se você tiver dúvidas, entre em contato com <a href=\'mailto:' + EMAIL_SUPPORT + '\'>nossa equipe de suporte</a>.'
  },
  ORDER_FORM: {
    SCOPE: {
      NO_OPEN_HELP: 'Sua empresa <b>ainda não pode operar no mercado Público</b>. Podemos precisar validar algumas coisas. Se você está interessado, entre em contato conosco pelo <a href="mailto:' + EMAIL_SUPPORT + '">' + EMAIL_SUPPORT + '</a>.'
    }
  },
  LANDING: {
    SECONDARY: 'Bem-vindo à plataforma<br>de negócios digitais.',
    TAGLINE: 'Potenciando juntos <span class="brand-color">al campo argentino</span>'
  },
  NOT_FOUND: {
    DESCRIPTION: 'Se você foi redirecionado para esta página pela plataforma, por favor entre em contato com nossa <a href="mailto:' + EMAIL_SUPPORT + '">equipe de suporte</a>.'
  },
  INTEGRATIONS: {
    API_DOCUMENTATION_HELP: 'Para acessar nossa documentação de API, entre em contato com nossa <a href="mailto:' + EMAIL_SUPPORT + '">equipe de suporte</a>.'
  },
  CLIENT_CREDENTIALS: {
    OAUTH: {
      TITLE: 'Autorize a conexão entre <b>{{company}}</b> e sua conta <b>Agrology</b>.',
      DESCRIPTION: 'Concorda em conceder a <b>{{company}}</b> acesso à sua conta? Permita que Agrology acesse sua conta do Agrology e desfrute de uma experiência integrada e aprimorada. Suas informações estarão seguras e usaremos apenas os dados de que precisamos.'
    }
  }
};
