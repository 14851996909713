import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import Theme from '../models/theme.model';
import themes from '../themes';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _theme$: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(null);
  private _defaultTheme: Theme = themes['agree'];
  private _themes = themes;

  constructor(
    private translateService: TranslateService
  ) {
    const hostname = window.location.hostname;
    // const hostname = 'dev-connect.agrology.com';
    const theme = this.getThemeByHostname(hostname) || this._defaultTheme;

    this._theme$.next(theme);
  }

  get theme(): Theme {
    return this._theme$.getValue();
  }

  get themes(): Theme[] {
    const themes = [];
    Object.keys(this._themes).forEach(themeKey => themes.push(this._themes[themeKey]));

    return themes;
  }

  public applyTheme(): void {
    const theme = this.theme;

    if (!window.document.body.classList.contains(theme.cssClass)) {
      window.document.body.classList.add(theme.cssClass)
    }

    // Apply Theme dictionary
    if (theme.languages?.messages) {
      for (const lang in theme.languages.messages) {
        if (Object.prototype.hasOwnProperty.call(theme.languages.messages, lang)) {
          const element = theme.languages.messages[lang];
          // shouldMerge to true to append the translations instead of replacing them
          this.translateService.setTranslation(lang, element, true);
        }
      }
    }

    this.injectFavicons(theme);
  }

  private injectFavicons(theme: Theme): void {
    const head = document.querySelector('head');

    for (const favicon of theme.favicons) {
      const newFavicon = document.createElement('link');

      newFavicon.rel = favicon.rel ?? 'icon';
      newFavicon.type = favicon.type;
      newFavicon.href = favicon.href;

      if (favicon.sizes) {
        newFavicon.setAttribute('sizes', favicon.sizes);
      }

      head.appendChild(newFavicon);
    }
  }

  private getThemeByHostname(hostname: string): Theme | undefined {
    // return this.themes.find(theme => theme.hostnames?.includes(hostname));
    return this.themes.find(theme =>
      theme.hostnames?.some(hn => this.matchesHostname(hn, hostname))
    );
  }

  private matchesHostname(pattern: string, hostname: string): boolean {
    if (pattern === hostname) return true; // Coincidencia exacta

    // Match con '*.dominio.com'
    if (pattern.startsWith("*.") && hostname.endsWith(pattern.substring(1))) return true;

    return false;
  }
}
