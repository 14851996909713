import { Token } from './token.model';
import { User } from './user.model';

/**
 * Mínimo 8 caracteres.
 * Al menos una letra minúscula.
 * Al menos una letra mayúscula.
 * Al menos un número.
 */
export const PASSWORD_PATTERN = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$' as const;

export class Login {
  public user: User;

  public readonly token_data?: Token;
}
