import { forwardRef } from '@angular/core';
import { Type } from "class-transformer";

import { environment } from '../../environments/environment';
import { ActivationLevel } from '../admin/models/activation-level.model';
import { CommercialZone } from '../company/models/commercial-zone.model';
import { Market } from '../company/modules/commercial/models/market.model';
import { Address } from './address.model';
import { CompanyActivity } from './company-activity.model';
import { FiscalId } from './fiscal-id.model';
import { PlatformModule } from './platform-module.model';

/**
 * A mapping of company activities to their respective IDs.
 */
export const COMPANY_ACTIVITIES: { [activity: string]: number[] } = {
  BROKER: [6, 13, 21, 28, 32, 48, 61, 82],
  COPIER_CONSIGNEE: [2, 9, 44, 57, 78],
  DOMESTIC_SURVEYOR: [42, 55, 68, 88],
  EXPORTER: [3, 10, 26, 31, 45, 58, 18, 79],
  FARMER: [1, 8, 16, 35, 43, 56, 7, 14, 49, 62, 77, 83],
  FINANCIAL_INSTITUTION: [41, 54, 67, 87],
  INDUSTRY: [5, 12, 20, 25, 30, 47, 60, 81],
  INPUT_DISTRIBUTOR: [38, 51, 64, 85],
  INPUT_SUPPLIER: [37, 50, 63, 76, 84],
  INSURANCE_DISTRIBUTOR: [40, 53, 66],
  MILL: [4, 11, 27, 46, 59, 80],
  STAND: [39, 52, 65]
};

class CompanyFile {
  id: number;
  file: string;
}

/**
 * A Company respresents a real-life organization in Agree, and can only
 * operate in one [[Market]].
 *
 * [[User|Users]] are linked to Companies through [[Account|Accounts]].
 *
 * ### Related UI components:
 * - [[CompanyInfoComponent]]
 * - [[CompanyLogoComponent]]
 * - [[CompanyTableComponent]]
 */
export class Company {

  /** @deprecated */
  activation_level_id: number;
  /**
   * @deprecated
   * The document's URLs of the Company statute. */
  statutes: CompanyFile[];
  /**
   * @deprecated
   * The document's URLs of the [[User|User's]] authorization to create the
   * Company.
   */
  authorizations: CompanyFile[];
  /**
   * @deprecated
   * The document's URLs of the [[User|User's]] Photo ID submitted to create
   * the Company.
   */
  id_cards: CompanyFile[];

  /** Agree's internal unique identifier. */
  readonly id: number;
  /** Company legal name. */
  name: string;
  /** Company extra name information when fiscal type is PERSON and not a COMPANY */
  names?: string;
  first_surname?: string; // Paternal surname
  second_surname?: string; // Mother's surname
  /** The [[Market]] in which this Company operates. */
  market: Market;
  /** UUID Original ID encrypted */
  readonly uuid: string;

  @Type(forwardRef(() => FiscalId) as any)
  fiscal_id: FiscalId;

  @Type(forwardRef(() => CompanyActivity) as any)
  activity: CompanyActivity;

  activation_level: ActivationLevel;

  /** The image's URL of the Company logo. */
  logo: string;

  /** The Companny document's URLs. */
  files?: {
    [key: string]: CompanyFile[]
  };

  /**
   * Used in Auctions. Timestamp when the Company enrolled for the Auction.
   */
  @Type(() => Date)
  confirmation?: Date;

  @Type(forwardRef(() => Address) as any)
  address: Address;
  phone: string;

  /** [[User]] who created the Company. */
  account: any;

  commercial_zones?: CommercialZone[];

  /** Role of the [[User|User's]] who created the Company. */
  account_owner: {
    position_id: number;
    position_name?: string;
    phone_number?: string;
  };

  /** [[PlatformModule|Platform modules]] enabled to the Company. */
  modules: PlatformModule[];

  /**
   * Used to add Company to a netwoek
   * @ignore */
  admin_email: string;

  /** Last update of the Company. */
  @Type(() => Date)
  updated_at: Date;

  /** Number of pending request for the Company. */
  pending_requests: number;

  /** Company's API key for [[https://www.ssta.gob.ar/turnos/|STOP]]. */
  stop_key?: string;

  set phoneNumber(phone: string) {
    this.phone = phone;
    this.account_owner.phone_number = phone;
  }

  get phoneNumber(): string {
    return this.phone;
  }

  /** Full name of the [[User]] who created the Company. */
  get accountFullName(): string {
    if (this.account && this.account.name && this.account.last_name) {
      return this.account.name + ' ' + this.account.last_name;
    } else {
      return null;
    }
  }

  /** For labeleable/zoneable entities. */
  get entity(): string {
    return 'company';
  };

  get active(): boolean {
    return this.activation_level ? (this.activation_level.id === 1 || this.activation_level.id === 2) : false;
  }

  private get modulesSlugs(): string[] {
    if (this.modules) {
      return this.modules.map(obj => obj['slug']);
    } else {
      return [];
    }
  }

  /**
   * Quick check to determine if a [[PlatformModule|Platform module]] is
   * enabled for the Company.
   */
  public hasModule(slug: string): boolean {
    const environmentSetting = environment.modules[slug.replace(/-/g, '_')];
    return environmentSetting !== false && this.modulesSlugs.indexOf(slug) !== -1;
  }

  constructor(data: Partial<Company> = {}) {
    this.account_owner = {
      position_id: null
    };
    this.activity = null;
    this.address = new Address();
    this.fiscal_id = new FiscalId();
    this.market = new Market();

    Object.assign(this, data);
  }
}

/**
 * Determines if a company has a specified activity.
 * 
 * @param {Company} company - The company to check.
 * @param {...string[]} keys - The activity keys to check against.
 * @returns {boolean} - True if the company has the specified activity, false otherwise.
 */
export function companyIs(company: Company, ...keys: string[]): boolean {
  for (let index = 0; index < keys.length; index++) {
    const activities_id = COMPANY_ACTIVITIES[keys[index]];
    if (activities_id && activities_id.includes(company.activity.id)) {
      return true;
    }
  }
  return false;
}

/**
 * Determines the actions a company can perform based on its activities.
 * 
 * @param {Company} company - The company to check.
 * @returns {{ [action: string]: boolean }} - An object with actions as keys and booleans indicating if the company can perform them.
 */
export function companyCan(company: Company): { [action: string]: boolean } {
  if (company) {
    return {
      produce: companyIs(company,
        'FARMER'
      ),
      createBarter: companyIs(company,
        'COPIER_CONSIGNEE',
        'BROKER',
        'INPUT_SUPPLIER',
        'INPUT_DISTRIBUTOR'
      ),
      beFunder: companyIs(company,
        'COPIER_CONSIGNEE',
        'EXPORTER',
        'INPUT_SUPPLIER',
        'BROKER',
        'FINANCIAL_INSTITUTION'
      ),
      manageApplications: companyIs(company,
        'COPIER_CONSIGNEE',
        'EXPORTER',
        'BROKER',
        'INPUT_SUPPLIER',
        'INPUT_DISTRIBUTOR',
        'INSURANCE_DISTRIBUTOR',
        'FINANCIAL_INSTITUTION'
      ),
      qualify: !companyIs(company,
        'EXPORTER',
        // 'FINANCIAL_INSTITUTION', // Enable Financial Institutions to create qualifications
        'DOMESTIC_SURVEYOR'
      )
    };
  }
}
